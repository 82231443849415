export const footerMenuList = [
  {
    title: 'Community',
    items: [
      {
        name: 'Twitter',
        href: 'https://twitter.com/gradle',
      },
      {
        name: 'Blog',
        href: '/blog/',
      },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/gradle/',
      },
      {
        name: 'YouTube',
        href: 'https://www.youtube.com/channel/UCvClhveoEjokKIuBAsSjEwQ',
      },
      {
        name: 'Newsletters',
        href: '/gradle-dpe-newsletters/',
      },
      {
        name: 'Gradle.org',
        href: 'https://gradle.org/?_ga=2.186161007.739834106.1696277564-1394687617.1696277564&_gl=1*16jh5uy*_ga*MTM5NDY4NzYxNy4xNjk2Mjc3NTY0*_ga_7W7NC6YNPT*MTY5NjI5MTY3OC4zLjAuMTY5NjI5MTY3OC42MC4wLjA.',
      },
    ],
  },
  {
    title: 'Get Started Now',
    items: [
      {
        name: 'Solutions Overview',
        href: '/develocity/',
      },
      {
        name: 'Develocity Demo (Video)',
        href: 'https://www.youtube.com/embed/4ARx80ns6XI',
      },
      {
        name: 'Request a Trial',
        href: '/enterprise/trial/',
      },
      {
        name: 'Contact',
        href: '/enterprise/contact/',
      },
    ],
  },
  {
    title: 'About',
    items: [
      {
        name: 'Our Story',
        href: '/our-story/',
      },
      {
        name: 'Our Team',
        href: '/our-team/',
      },
      {
        name: 'Careers',
        href: '/careers/',
      },
      {
        name: 'Brand',
        href: '/brand/',
      },
      {
        name: 'Press & Media',
        href: '/press-media/',
      },
    ],
  },
];

export const FooterBottomNavLinks = [
  {
    name: 'Privacy Policy',
    href: '/legal/privacy/',
  },
  {
    name: 'Terms',
    href: '/legal/terms-of-use/',
  },
  {
    name: 'Status',
    href: 'https://status.gradle.com/?_gl=1*5qcfh0*_ga*MTM5NDY4NzYxNy4xNjk2Mjc3NTY0*_ga_7W7NC6YNPT*MTY5NjI3NzU2NC4xLjAuMTY5NjI3NzU2NS41OS4wLjA.',
  },
];
