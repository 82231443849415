'use client';

import { XMarkIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

type BannerProps = {
  handleCloseBanner: () => void;
  isBannerOpen: boolean;
};

function Banner({ handleCloseBanner, isBannerOpen }: BannerProps) {
  // check with growth marketing team before we edit these.
  const promoAttributes = {
    'data-promo': true,
    'data-promo-offer': 'webinar_041625',
    'data-promo-location': 'gradle.com',
    'data-promo-type': 'link',
  };

  const url =
    'https://gradle.com/events/failure-summaries-webinar-develocity-04-16/?utm_campaign=failure-summaries&utm_medium=website-banner&utm_source=gradle-com&utm_content=site-banner-webinar-041625';

  return (
    <div
      className={clsx(
        'bg-custom-gradient overflow-hidden transition-all duration-500',
        isBannerOpen ? 'max-h-[--navbarBannerMobileHeight]' : 'max-h-0',
      )}
      data-testid="announcement-banner"
      data-banner-open={isBannerOpen}
    >
      <div className="max-w-section flex h-[--navbarBannerMobileHeight] items-center justify-center px-3 py-[0.625rem] text-center text-body-md text-white md:h-[--navbarBannerHeight]">
        <p className="w-full leading-[18px]">
          <a href={url} className="mega-menu-link underline" {...promoAttributes}>
            Apr-16 webinar: Why did my build fail? Using AI to troubleshoot failures
          </a>
        </p>
        <button
          onClick={handleCloseBanner}
          className="cursor-pointer rounded-full p-1 transition-colors hover:bg-gradle-blue"
        >
          <XMarkIcon className="size-5 transition-transform md:size-4" />
        </button>
      </div>
    </div>
  );
}

export default Banner;
